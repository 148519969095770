.knowing-the-sounds-container {
  background-color: #5550a5;
  margin: 20px;
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
  color:aliceblue;
  min-height: 800px;
  
}

.restart-button{
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 100px;
  position:relative;
  z-index:1;
  bottom: 40px;
}

.restart-button:hover{
  background-color: rgb(227, 98, 98);
  color: white;
}

.game-1-container{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  justify-content: center;  
}

.game-space-game-1{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mascot-planet-1-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-size: 30px;

}

.mascot-planet-1{
  background-color: red;
  width: 100px;
  height: 100px;
  border-radius: 50%;

}

.button-sound-game-1{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  background-color: rgb(48, 35, 124);
  font-size: 30px;
  cursor: pointer;
  color: white;
  margin-top: 50px;
}

.button-sound-game-1:hover{
  background-color: rgb(48, 35, 124);
  transition: 0.5s;
}

.button-next-level{
  width: 100px;
  height: 60px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.knowing-the-sounds-letters{
  min-width: 300px;
  min-height: 300px;
  border:none;
  border-radius: 10px;
  margin: 20px;
  font-size: 180px;
  background-color: rgb(255, 218, 97);
  color:rgb(48, 35, 124);
  cursor: pointer;
  
}

.knowing-the-sounds-elements-game{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.reiforcement-for-next-level{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.next-level-button{
  width: 200px;
  height: 70px;
  background-color: rgb(64, 148, 116);
  border: none;
  border-radius: 10px;
  color: rgb(3, 57, 47);
  font-size: 20px;
  font-family: 'Kanit', sans-serif;
  cursor: pointer;
}

.knowing-the-sounds-volume{
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 50%;
  top: 450px;
  cursor: pointer;
}

.image-sound{
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.button-sound-icon{
  background-color: #5550a5;
  border:none
}