.card-testezinho{
  width: 270px;
  height: 270px;
}

.card__content {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  transform-style: preserve-3d;
  transition: transform 600ms ease-in-out;
  font-family: 'Kanit', sans-serif;
  
}

.card__content--flipped {
  transform: rotateY(180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;
  cursor: pointer;
  border-radius: 10px;
}

.card__face--front {
  background-color:rgb(21, 21, 72);
  color: antiquewhite;
}

.card__face--back {
  background-color: rgb(252, 237, 126);
  color: rgb(14, 14, 76);
  transform: rotateY(180deg);

}
