body{
  background-image: url(https://static.vecteezy.com/system/resources/previews/000/190/741/original/ultra-violet-galactic-background-free-vector.jpg);
}

.links-dos-planetas-container{
  display: flex;
  flex-direction: column;
  
}

.link {
  color: aliceblue;
  text-decoration: none;
  font-family: arial;
  font-size: 18px;
  padding: 5px;
}


.home-container{
  background-color: rgb(79, 43, 255);
  height:50% ;
}

.image-home{
  width: 900px;
  height: 900px;
}

.home-body-container{
  display: flex;
  justify-content: space-around;
  align-items:center ;
  color: aliceblue;

}
.home-title{
  font-size: 100px;
  font-family: arial;
}

.home-text{
  width: 600px;
  font-size: 24px;
  font-family: 'Arial';
  line-height: 2.0;
}

.home-info-elements{
  margin-left: 100px;
}

.home-init-button{
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 50px;
  font-size: 20px;
  background-color: rgb(255, 246, 82);
  color: rgb(17, 1, 43);
  margin-top: 50px;
  line-height: 1.5;
}

.home-init-button:hover{
  background-color: rgb(89, 249, 134);
  width: 180px;
  height: 55px;
}

.about-abc-go{
  min-height: 800px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: end;
}

.about-abc-go-text{
 width: 700px;
 margin: 100px;
 font-family: arial;
 font-size: 20px;
 line-height: 2.0;

}

.collaborators{
  height: 400px;
  background-color: rgb(39, 18, 70);
  display: flex;
  color: white;
  justify-content: center;

}

.person-collaborator{
  width: 120px;
  height: 120px;
  border:4px solid white;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: arial;
}

.person-collaborator-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px;
}

.person-collaborator-text{
 width: 200px;
 font-family: arial;
 line-height: 1.5;
}