.grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;

}

.scoreboard{
  color: rgb(183, 211, 230);
  font-size: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  
}

.memory-game-restart-button{
  position: absolute;
  z-index: 1;
  left:1670px;
  bottom: 640px;
}


.button-reset{
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background-color: rgb(97, 131, 217);
  color: white;
  margin-bottom: 20px;
  font-size: 18px;
  border:none;
}

.button-reset:hover{
  background-color: rgb(215, 46, 74)
}