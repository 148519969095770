.phoneme-identification-game-container {
  background-color: rgb(85, 80, 165);
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

.phoneme-identification-elements-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.mascot-message-3{
  font-family: 'Kanit', sans-serif;
  color:aliceblue;
}

.letter-bar{
  display: flex;
  flex-wrap: wrap;
}

.images-planet-3{
  width: 40%;
  height: 40%;
}

.phoneme-identification-letters{
  min-width: 100px;
  min-height: 100px;
  border:none;
  border-radius: 10px;
  margin: 20px;
  font-size: 60px;
  background-color: rgb(255, 218, 97);
  color:rgb(48, 35, 124);
  cursor: pointer;
}

.phoneme-identification-restart-button{
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  align-self: flex-end;
  position: absolute;
  z-index: 1;
  bottom: 660px;
  right: 90px;
}


.parabens-container{
  font-family: 'Kanit';
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}