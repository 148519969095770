// *{
//   @media screen and (max-width: 769px) {
//     width: 50%;
//     height: 50%;
//   }
// }

.choose-the-planets-all-planets{
  background-color: rgb(39, 18, 70);
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  display:flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
 


  .planets-container{
    display: flex;
    flex-direction: column;
    // flex-direction: column-reverse;
    font-family: 'Kanit', sans-serif;
    width: 30%;
    // height: 30%;
    max-height:2300px;

    @media screen and (max-width: 899px) {
      height: 2050px;
    }

    @media screen and (max-width: 370px) {
      width: 100%;
      height: 2200px;
    }
    
    button{
      background: none;
      border:none;
    }

    .planet-img {
      cursor: pointer;
      transition: transform 0.9s ease;
      position: relative;
      width: 110%;
      height: 110%;
    }

    .planet-img:hover {
      width: 110%;
      height: 110%;
      transform: scale(1.1);
    }
    
    .planet-img-off{
      filter: grayscale(100%);
    }
  }
  
}

.planetas-trilha{
position: relative;
z-index: 2;
bottom: 2200px;
display: flex;
flex-direction: column-reverse;

@media screen and (max-width: 899px) {
  bottom: 2000px;
}

@media screen and (max-width: 370px) {
  bottom: 2200px;
}

}

.line-container{
  align-self: center;
}

.line{
  border: 2px dashed rgb(249, 249, 249);
  height: 2290px;
  position:relative;
  z-index: 1;

  @media screen and (max-width: 899px) {
    height: 2000px;
  }

  @media screen and (max-width: 370px) {
    height: 2200px;
  }

}

.names-left-side{
  display:flex;
  flex-direction: column;
  width: 25%;
  align-self: start;
  gap: 460px;
  margin-top: 160px;
  color: white;
  font-family: arial;

  @media screen and (max-width: 899px) {
    display: none;
  }
  @media screen and (max-width: 370px) {
    display: none;
  }
}

.names-right-side{
  display:flex;
  flex-direction: column;
  width: 25%;
  align-self: start;
  gap: 460px;
  margin-top: 480px;
  color: white;
  font-family: arial;

  @media screen and (max-width: 899px) {
    display: none;
  }
  @media screen and (max-width: 370px) {
    display: none;
  }
}
