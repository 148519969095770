.imagemgato{
  width: 350px;
  height: 350px;

  &.active{
    filter: grayscale(100%);
  }
}

.phoneme-isolation-game-container {
  background-color: rgb(85, 80, 165);
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phoneme-isolation-game-container{
  background-color: rgb(85, 80, 165);
  margin: 20px;
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
  color:aliceblue;
  min-height: 800px;
}

.phoneme-isolation-elements-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
}

.phoneme-isolation-images-game{
  display: flex;
  gap: 20px;
}

.phoneme-isolation-restart-button{
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  align-self: flex-end;
  position: absolute;
  z-index: 1;
  bottom: 670px;
  right: 90px;

}

.phoneme-isolation-restart-button:hover{
  background-color: rgb(227, 98, 98);
  color: white;
}

.phoneme-isolation-title{
  font-size: 50px ;
}

.phoneme-isolation-button-sound{
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 420px;
  cursor: pointer;
}

.phoneme-isolation-images{
  width: 300px;
  height: 300px;
}

.placar{
  font-size: 22px;
}
// .container {
//   text-align: center;
//   padding: 20px;
// }

// h1 {
//   font-size: 24px;
//   margin-bottom: 20px;
// }

// .letter {
//   display: inline-block;
//   margin: 10px;
//   padding: 10px 20px;
//   width: 300px;
//   height: 300px;
//   background-color: lightblue;
//   cursor: pointer;
// }

// .letter:hover {
//   background-color: skyblue;
// }

// .grayscale {
//   filter: grayscale(100%);
// }

// .victory-message {
//   font-size: 20px;
//   margin-top: 20px;
// }

// .start-button {
//   margin-top: 20px;
// }

// .image-of-game{
//   width: 300px;
//   height: 300px;
// }