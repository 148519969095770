
.exploring-the-alphabet-container {
  background-color: rgb(85, 80, 165);
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}
.all-cards-letter-container{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 80px 80px 80px;
  gap: 10px;
}

.alphabet-card-with-letter{
  background-color: rgb(255, 218, 97);
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  border-radius: 10px;
  color: rgb(85, 80, 165);
  border: none;
  cursor: pointer;
  
}

.alphabet-card-with-letter:hover{
 background-color: rgb(244, 201, 61);
 transition: 0.5s;
}

.material-symbols-outlined{
  border:none;
  border-radius: 50%;
}

.explore-the-alphabet-header{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin: 50px;

  .phrase-alphabet{
    font-family: 'Kanit', sans-serif;
    color:aliceblue;
  }

  button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:none;
    color:rgb(255, 255, 255);
    background-color: darkslateblue;
  }
}