
.login-elements{
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 20px;
min-height: 700px;
}


.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 564px;
  min-height: 400px;
  padding: 40px;
  background:rgba(240, 248, 255, 0.628);
  border-radius: 5px;
  font-family: 'Arial';
  font-size: 16px;
  margin:auto;
  color: #0e3061;
  filter: drop-shadow(98%);
  border: none;
  font-family: arial;
  
}

.password{
  margin-top: 20px;
}

#title-login{
  font-family: 'arial';
  margin-bottom: 10px;
  color: rgb(26, 26, 81);
  font-size: 40px;
}


.input-login {
  background-color: rgb(26, 26, 81);
  border-radius: 5px;
  height: 30px;
  width: 300px;
  color: white;
  border:none;
  padding: 20px;
  font-size: 16px;
}

.button-access{

  padding: 8px;
  margin-top: 12px;
  border-radius: 5px;
  height: 40px;
  width: 130px;
  cursor:pointer;
  background: white;
  font-size: 16px;
  border: none;
  margin-top: 40px;
}

.button-access:hover{
  background-color: rgb(61, 127, 233);
  color: white;
}
