.all-set-letters{
  display: flex;
  justify-content: space-around;
  font-size: 50px;
  flex-wrap: wrap;
  cursor: pointer;
  width: 60%;
}


.each-set-letter{
  background-color: rgb(177, 81, 233);
  width: 110px;
  height: 110px;
  margin:10px;
  border:none;
  border-radius: 50%;
  font-size: 40px;
  cursor: pointer;
  color: rgb(35, 3, 109);
}

.each-set-letter:hover{
  background-color:rgb(160, 52, 222); 

}

.set-letter-title{
  font-size: 60px;
  font-family: 'Kanit', sans-serif;
  color: rgb(3, 10, 59);
}

.set-letter-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
  min-height: 850px;
  color:rgb(35, 3, 109);
}

