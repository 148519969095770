.container-all-activitys{
  background-color: rgb(85, 80, 165);
  border-radius: 10px;
  justify-content: space-around;
  font-family: 'Kanit', sans-serif;
  min-height: 900px ;

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .choose-activity-title{
    color: rgb(252, 254, 255);
    display: flex;
    justify-content: center;
    padding: 50px 0px 0px 0px;

    @media (max-width: 1000px) {
      font-size: 25px;
      padding: 20px 20px 0px 20px;
    }
  }

  .choose-activity-button {
    background: none;
    border-radius: 10px;
    border: none;

    
  }
}

.container-carousel{
  max-width: 600px;

  @media (max-width: 1000px) {
   max-width: 300px;
   max-height:auto ;
  }
}

.planet-img{
  width: 600px;
  height: 500px;
  border-radius: 10px;
}

.title-of-planet{
  font-size: 30px;
  font-family: 'Kanit';
  color:rgb(23, 3, 48)
}