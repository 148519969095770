.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin:20px;
  color: #0e3061;
  min-height: 700px;
}

.home-body{
  display: flex;
}
.inputs-container{
  background-color:rgba(240, 248, 255, 0.628);
  font-size:20px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 30px;
  width: 700px;
  height: 460px;
  border-radius: 10px;
}


.title {
  text-align: center;
  font-family: 'Arial';
  margin-bottom: 10px;
  color: rgb(39, 38, 81);
  font-size: 40px;
  
}

.into-input {
  border:none;
  border-radius: 5px;
  color: #75529e;
  // min-height: 52px;
  // min-width: 300px;
  width: 200px;
  font-size: 16px;
  color: white;
  margin: 10px;
  border-bottom: solid 1px;
  background-color: rgb(26, 26, 81);
  padding: 20px;
  
}

.signup-container button {
  padding: 8px;
  margin-top: 12px;
  border-radius: 5px;
  height: 40px;
  width: 130px;
  cursor:pointer;
  background-color: white;
  font-size: 16px;
  border: none;
}

.signup-container button:hover{
  background-color: rgb(61, 127, 233);
  color: aliceblue;
}