.set-level-title{
  font-size: 60px;
  font-family: 'Kanit', sans-serif;
  color: rgb(3, 10, 59);
}

.set-level-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
  min-height: 800px;
}

.button-level{
  width: 200px;
  height: 200px;
  margin: 20px;
  border-radius: 50%;
  border:none;
  background-color: rgb(177, 81, 233);
  font-size: 50px;
  cursor: pointer;
  font-size: 70px;
  color: rgb(3, 10, 59);
}

.button-level:hover{
  background-color:rgb(160, 52, 222); 
}
