.memory-game-container {
  background-color: rgb(85, 80, 165);
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 900px;
  font-family: 'Kanit', sans-serif;
}

.memory-game-restart-button{
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  align-self: flex-end;

  bottom: 400px;

  cursor: pointer;
}

.memory-game-restart-button:hover{
  background-color: rgb(227, 98, 98);
  color: white;
}